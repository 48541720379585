
import React from "react"
import Type from "../../components/typography/Type"


const HorizonCard = (props) => {	
	
const horizon = props.horizon; 	
	
	switch (horizon) {
		
		case "Development":
		return (
		  <span className={`bg-yellow rounded-full px-4 py-2 font-bold`}>{horizon}</span>			
		)
		case "Target":
		return (
		  <span className={`bg-yellow/75 rounded-full px-4 py-2 font-bold`}>{horizon}</span>			
		)
		case "Future":
		return (
		  <span className={`bg-yellow/25 rounded-full px-4 py-2 font-bold`}>{horizon}</span>			
		)
		default:
		return (
		<span className={`bg-yellow/25 rounded-full px-4 py-2 font-bold`}>{horizon}</span>			
					
		)
	
		
		
	};
};

export default HorizonCard;
