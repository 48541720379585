
import React from "react"
import Type from "../../components/typography/Type"
import { useState } from 'react'

export default function Collapsed(props) {

const children = props.children
const title = props.title
const titlesize = props.titlesize
const [isOpen, toggleOpen]  = useState(false);


	return (

<div>
	<div className="flex flex-row">	
		<div className="flex items-center mt-4">
			{titlesize
				?
				<Type type={`${titlesize}`} nopadding="yes">{title}</Type>
				:
				<Type type="h4" nopadding="yes">{title}</Type>
			}
			</div>
		<div className="flex items-center justify-end grow">
			<button onClick={() => toggleOpen(!isOpen)} className="">
				<img
				src="https://ik.imagekit.io/pathventures/StreamlineCoreLineThin/Action/ArrowDown.svg"
				alt="Down Arrow"
				className="h-8"
				/>
			</button>
		</div>
	</div>
	<div className={`${isOpen ? `block` : `hidden` } mt-4`}>
	<>
	{children}
	</>
	</div>
</div>
)
}