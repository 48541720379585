import * as React from "react"
import { graphql } from "gatsby"
import CanvasLayout from "../../components/layout/CanvasLayout"
import Container from "../../components/layout/Container"
import Type from "../../components/typography/Type"
import { isLoggedIn } from "../../services/auth"
import LoginPrompt from "../../components/app/loginPrompt"
import UserNeedCard from "../../components/cards/UserNeedCard"
import FeatureCard from "../../components/cards/FeatureCard"
import Toggle from "../../components/controls/Toggle"
import Layout from "../../components/layout/Layout"
import ProcessCard from "../../components/cards/ProcessCard"
import Collapsed from "../../components/controls/Collapsed"

import HorizonCard from "../../components/cards/HorizonCard"
import CanvasToolbar from "../../components/canvas/CanvasToolbar"
import Canvas from "../../components/canvas/Canvas"

import { useState } from "react"



export default function Pages( {data}) {

const [isOpen, toggleOpen] = useState(false);

return (
  <>
  <CanvasLayout>
  {isLoggedIn() ? (

<div>

  <CanvasToolbar>
    <div><a href={data.airtable.data.ProjectPath}><span className="">&lt; Back to {data.airtable.data.ProjectTxt}</span></a></div>
  </CanvasToolbar>


 <Canvas title={data.airtable.data.Name} introduction={data.airtable.data.Introduction}>  
    
    
    
    {data.airtable.data.Products
      ?
      <div className="flex flex-col lg:flex-row p-16 space-x-8">
          <div className="min-w-[320px]"/>
          {data.airtable.data.Products.map((ProductsArray, index) => (
            <div className="">
            {index == 0 ? <div className="absolute left-96 min-w-[320px] text-green uppercase p-8 "><Type type="p4" nopadding="yes">Product</Type></div> : '' }
              <div className="bg-yellow text-center w-full p-8 min-w-[320px] h-40 overflow-auto flex items-center mb-8 ">
                <Type type="h5" nopadding="yes">{ProductsArray.data.Name}</Type>
              </div>
              <div className="flex flex-col lg:flex-row space-x-8">

                {ProductsArray.data.NeedsWhat
                ?
                <>
                {ProductsArray.data.NeedsWhat.map((NeedsArray) => (
                  <div>
                  {index == 0 ? <div className="absolute left-96 min-w-[320px] text-green uppercase p-8 "><Type type="p4" nopadding="yes">Needs</Type></div> : '' }

                    <div className="w-[320px] p-8 bg-orange h-64"><Type type="p6" nopadding="yes">{NeedsArray.data.Name}</Type></div>
                    
                  </div>
                ))}
              </>
              :
              undefined
            }
             
             
             {ProductsArray.data.Features
               
               ?
               <>
                   
                    
                    
              <div className="flex flex-col space-y-2 overflow-auto h-64 mt-12">
              {index == 0 ? <div className="absolute left-96 min-w-[320px] text-green uppercase p-8 "><Type type="p4" nopadding="yes">Features</Type></div> : '' }

                {ProductsArray.data.Features.map((FeaturesArray) => (
                  <FeatureCard title={FeaturesArray.data.Name} horizon={FeaturesArray.data.HorizonTxt}/> 
                ))}
              </div>
              </>
              :
              undefined
            }
   
 
 
                            
              </div>
            </div>
          ))}
      </div>
    
      :
      <div className="h-screen w-full flex items-center justify-center">
      No Products in this Story yet
      </div>
    }
      
  </Canvas>

</div>
   
   ) : (
     <div>
       <LoginPrompt/>
     </div>     
   )}
  </CanvasLayout>
    </>

  )
}

export const Head = () => <title>Journey</title>


export const query = graphql`
  query GetPage($Path: String!) {
    airtable(table: { eq: "ProductStories" },
    data: { Path: { eq: $Path } }) {
      data {
        Name  
        ProjectTxt
        ProjectPath      
        Products { data {
          Name
          Features {data {
            Name
            HorizonTxt
          }}
          NeedsWhat {data {
            Name
          }}
        }}
      }
    }
  }
`
